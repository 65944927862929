import React from 'react'
import { Link } from 'gatsby'

import { CustomerMessageSection, CustomerMessageWrapper, Text } from './styles'

import Container from '../../UI/Container'
import Button from '../../Button'

const CustomerMessage = ({ data, location }) => {
  const { content, button_text, button_link } = data
  return (
    <CustomerMessageSection>
      <Container>
        <CustomerMessageWrapper>
          <Text dangerouslySetInnerHTML={{ __html: content }} />
          <Button as={Link} to={`${button_link}${location.search}`} type="arrow">
            {button_text}
          </Button>
        </CustomerMessageWrapper>
      </Container>
    </CustomerMessageSection>
  )
}

CustomerMessage.defaultProps = {
  location: {
    search: ''
  }
}

export default CustomerMessage
