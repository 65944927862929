import styled from 'styled-components'

export const TestimonialListWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  position: relative;
  padding: 8rem 0;
  overflow: hidden;
  z-index: 1;

  &::after {
    content: '';
    position: absolute;
    top: 0;
    left: 50%;
    width: 700px;
    height: 200px;
    background: radial-gradient(
        49.33% 46.98% at 50.67% 46.98%,
        rgba(169, 207, 58, 0.26) 0%,
        rgba(255, 255, 255, 0) 100%
      ),
      radial-gradient(45.7% 43.52% at 49.85% 100.17%, rgba(29, 158, 217, 0.6) 0%, rgba(29, 158, 217, 0) 100%);
    opacity: 0.3;
    z-index: -1;
    transform: translate(-50%, 49%);
  }

  @media only screen and (min-width: 1025px) and (max-width: 1060px) {
    &::after {
      transform: translate(-50%, 29%);
    }
  }
`
export const TestimonialCard = styled.div`
  display: flex;
  flex-direction: column;
  width: 360px;
  height: 428px;
  padding: 2.85rem;
  background: #ffffff;
  box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.05);
  border-radius: 10px;
  ${({ marginTop }) => (!marginTop ? null : `margin-top: ${marginTop}rem`)};

  @media only screen and (min-width: 1025px) and (max-width: 1200px) {
    width: 300px;
  }
`
export const TestimonialCardContent = styled.div`
  color: ${({ theme }) => theme.colors.darkGrey};
  font-size: 1.14rem;
  line-height: inherit;
  margin-bottom: auto;

  a {
    color: ${({ theme }) => theme.colors.vividBlue};

    &:hover {
      text-decoration: underline;
    }
  }
`
export const TestimonialCardFooter = styled.div`
  display: flex;
  align-items: center;
  line-height: 1.4;

  > div {
    flex-basis: 75%;
  }
  figure {
    align-self: center;
    width: 45px;
    height: 45px;
    border-radius: 50%;
    overflow: hidden;
    margin-right: 1rem;
  }
`
export const Name = styled.span`
  display: block;
  color: ${({ theme }) => theme.colors.veryDarkBlue};
  font-size: 1rem;
  font-weight: 500;
`

export const Role = styled.span`
  color: ${({ theme }) => theme.colors.veryDarkBlue};
  opacity: 0.6;
`
