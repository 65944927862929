import styled from 'styled-components'

export const TestimonialListMobileWrapper = styled.div`
  position: relative;
  margin: 0 auto;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 50%;
    width: 700px;
    height: 200px;
    background: radial-gradient(
        49.33% 46.98% at 50.67% 46.98%,
        rgba(169, 207, 58, 0.26) 0%,
        rgba(255, 255, 255, 0) 100%
      ),
      radial-gradient(45.7% 43.52% at 49.85% 100.17%, rgba(29, 158, 217, 0.6) 0%, rgba(29, 158, 217, 0) 100%);
    opacity: 0.3;
    z-index: 0;
    transform: translate(-50%, -21%);
  }

  @media only screen and (max-width: 1024px) {
    &::before {
      display: none;
    }
  }

  .slick-slide {
    width: 370px;
  }
  .slick-slide > div > div {
    &:focus {
      outline: none;
    }
  }
  .slick-dots {
    bottom: -35px;

    li {
      margin: 0 0.5rem;
      width: 40px;
      height: 5px;
    }
  }

  .slick-dots li button:before {
    content: '';
    font-size: 12px !important;
    background-color: ${({ theme }) => theme.colors.lightGrey};
    width: 40px;
    height: 5px;
    border-radius: 2px;
  }

  .slick-dots li.slick-active button:before {
    background-color: ${({ theme }) => theme.colors.vividBlue} !important;
    opacity: 1 !important;
    border-radius: 2px;
  }

  .slick-dots li button {
    color: ${({ theme }) => theme.colors.veryLightGray};
    &::before {
      color: ${({ theme }) => theme.colors.grey};
    }
  }
  .slick-dots li button:focus:before {
    opacity: 0.25;
  }
  .slick-dots li.slick-active button:focus:before,
  .slick-dots li button:hover:before {
    opacity: 1;
  }
`
export const TestimonialCard = styled.div`
  display: flex;
  flex-direction: column;
  width: 360px;
  height: 428px;
  padding: 2rem;
  background: #ffffff;
  box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.05);
  border-radius: 10px;
  margin: 3rem auto;

  @media only screen and (max-width: 425px) {
    width: 300px;
  }
`
export const TestimonialCardContent = styled.div`
  color: ${({ theme }) => theme.colors.darkGrey};
  font-size: 1.14rem;
  line-height: 1.71rem;
  margin-bottom: auto;
`
export const TestimonialCardFooter = styled.div`
  display: flex;
  align-items: center;
  line-height: 1.4;

  > div {
    flex-basis: 75%;
  }

  figure {
    align-self: center;
    flex-basis: 25%;
    width: 50px;
    height: auto;
    border-radius: 50%;
    overflow: hidden;
    margin-right: 1rem;
  }
`
export const Name = styled.span`
  display: block;
  color: ${({ theme }) => theme.colors.veryDarkBlue};
  font-size: 1rem;
  font-weight: 500;
`

export const Role = styled.span`
  color: ${({ theme }) => theme.colors.veryDarkBlue};
  opacity: 0.6;
`
