import styled from 'styled-components'

export const WhyRentlioSliderWrapper = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;

  .active {
    z-index: 0;
    opacity: 1;
  }
`
