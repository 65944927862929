import React from 'react'
import TestimonialListSlider from 'react-slick'

import {
  TestimonialListMobileWrapper,
  TestimonialCard,
  TestimonialCardContent,
  TestimonialCardFooter,
  Name,
  Role
} from './styles'

const TestimonialListMobile = ({ data }) => {
  const {
    testimonial_list: {
      text_one,
      name_one,
      role_one,
      user_image_one,
      text_two,
      name_two,
      role_two,
      user_image_two,
      text_three,
      name_three,
      role_three,
      user_image_three
    }
  } = data

  const settings = {
    dots: true,
    arrows: false,
    fade: true,
    autoplay: false,
    autoplaySpeed: 3000,
    speed: 1000
  }
  return (
    <TestimonialListMobileWrapper>
      <TestimonialListSlider {...settings}>
        <div style={{ width: 320 }}>
          <TestimonialCard>
            <TestimonialCardContent dangerouslySetInnerHTML={{ __html: text_one }} />
            <TestimonialCardFooter>
              <figure>
                <img src={user_image_one.localFile.childImageSharp.fluid.src} alt="" />
              </figure>
              <div>
                <Name>{name_one}</Name>
                <Role>{role_one}</Role>
              </div>
            </TestimonialCardFooter>
          </TestimonialCard>
        </div>
        <div style={{ width: 320 }}>
          <TestimonialCard>
            <TestimonialCardContent dangerouslySetInnerHTML={{ __html: text_two }} />
            <TestimonialCardFooter>
              <figure>
                <img src={user_image_two.localFile.childImageSharp.fluid.src} alt="" />
              </figure>
              <div>
                <Name>{name_two}</Name>
                <Role>{role_two}</Role>
              </div>
            </TestimonialCardFooter>
          </TestimonialCard>
        </div>
        <div style={{ width: 320 }}>
          <TestimonialCard>
            <TestimonialCardContent dangerouslySetInnerHTML={{ __html: text_three }} />
            <TestimonialCardFooter>
              <figure>
                <img src={user_image_three.localFile.childImageSharp.fluid.src} alt="" />
              </figure>
              <div>
                <Name>{name_three}</Name>
                <Role>{role_three}</Role>
              </div>
            </TestimonialCardFooter>
          </TestimonialCard>
        </div>
      </TestimonialListSlider>
    </TestimonialListMobileWrapper>
  )
}

export default TestimonialListMobile
