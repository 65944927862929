import styled from 'styled-components'

export const WhyRentlioIntroWrapper = styled.div`
  padding: 8rem 0;
  height: 100%;
  background: radial-gradient(81.05% 104.48% at 65.31% 72.22%, #c41432 0%, #fc5a44 100%);
  background-size: cover;
  background-position: bottom;
  position: relative;

  display: flex;
  justify-content: center;
  align-items: flex-end;

  &::before {
    content: '';
    position: absolute;
    background: white;
    width: 100%;
    height: 100px;
    bottom: -2px;

    @supports (clip-path: polygon(0 0)) or (-webkit-clip-path: polygon(0 0)) {
      -webkit-clip-path: polygon(0 100%, 100% 100%, 100% 98%, 0% 55%);
      -webkit-backface-visibility: hidden;
      -moz-backface-visibility: hidden;
      backface-visibility: hidden;
      clip-path: polygon(0 100%, 100% 100%, 100% 98%, 0% 55%);
    }
  }

  @media only screen and (max-width: 425px) {
    padding-top: 8rem;
  }
`
export const WhyRentlioIntroBox = styled.div`
  display: flex;
  justify-content: space-between;
  position: relative;
  margin: 15rem 0 8rem 0;

  @media only screen and (max-width: 1180px) {
    flex-direction: column;
    align-items: center;
  }

  @media screen and (max-width: 425px) {
    margin: 11rem 0 1rem 0;
  }
`

export const Description = styled.div`
  display: flex;
  flex-direction: column;
  flex-basis: 50%;
  justify-content: center;

  @media only screen and (max-width: 1180px) {
    align-items: center;
    order: 1;
  }
`

export const Title = styled.h1`
  color: ${({ theme }) => theme.colors.lightestGrey};
  font-family: 'Barlow';
  font-size: 8.57rem;
  font-weight: 900;
  line-height: 8.57rem;
  position: absolute;
  top: -25%;
  width: 80%;
  z-index: 1;

  @media only screen and (max-width: 1180px) {
    font-size: 5.28rem;
    line-height: 6.57rem;
    text-align: center;
    margin-bottom: 2rem;
  }

  @media only screen and (max-width: 768px) {
    font-size: 4.28rem;
    line-height: 4.57rem;
    text-align: center;
    width: 85%;
  }

  @media only screen and (max-width: 510px) {
    font-size: 3rem;
    line-height: 3.5rem;
    text-align: center;
    top: -18%;
  }
`
export const Text = styled.div`
  color: ${({ theme }) => theme.colors.lightestGrey};
  font-family: 'Barlow';
  font-size: 1.42rem;
  font-weight: 500;
  line-height: 2.14rem;
  margin-top: 18rem;

  @media only screen and (min-width: 768px) and (max-width: 1180px) {
    width: 75%;
  }

  @media only screen and (max-width: 767px) {
    width: 100%;
  }

  @media screen and (max-width: 425px) {
    margin-top: 6rem;
  }
`
export const ImageContainer = styled.figure`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  max-width: 500px;
  height: 600px;
  width: 100%;
  position: relative;
  z-index: 0;

  &::after {
    content: '';
    width: 470px;
    height: 424px;
    position: absolute;
    background: #bd232b;
    opacity: 0.3;
    transform: rotate(-3deg);
    z-index: -1;
    left: -29px;
  }
  &::before {
    content: '';
    width: 470px;
    height: 424px;
    position: absolute;
    background: #bd232b;
    opacity: 0.5;
    transform: rotate(-3deg);
    z-index: -1;
    top: 63px;
    left: -20px;
  }

  span::after {
    width: 470px;
    content: '';
    height: 424px;
    position: absolute;
    background: #bd232b;
    opacity: 0.8;
    transform: rotate(-3deg);
    z-index: -1;
    top: 16px;
    left: -8px;
  }

  img {
    border-radius: 10px;
  }

  .top {
    animation-name: fade;
    animation-timing-function: ease-in-out;
    animation-iteration-count: infinite;
    animation-duration: 4s;
    animation-direction: alternate;
  }

  @keyframes fade {
    0% {
      opacity: 1;
    }
    25% {
      opacity: 1;
    }
    75% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }

  /* @media only screen and (max-width: 1180px) {
    margin-top: 3rem;
  } */

  @media only screen and (max-width: 768px) {
    max-width: 400px;
    height: 410px;

    &::after,
    &::before,
    & span::after {
      display: none;
    }
  }

  @media only screen and (max-width: 425px) {
    margin-top: -3rem;
  }
`
