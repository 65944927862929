import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { useInterval } from '../../Slider/useInterval'

import { WhyRentlioSliderWrapper } from './styles'
import WhyRentlioSlide from './Slide'

const WhyRentlioSlider = ({ slides, locale }) => {
  const [state, setState] = useState({
    slides,
    activeSlide: 0,
    autoPlay: true,
    interval: 5000
  })

  useInterval(
    () => {
      setState(prev => ({
        ...prev,
        activeSlide: (prev.activeSlide + 1) % prev.slides.length
      }))
    },
    state.autoPlay ? state.interval : null
  )

  return (
    <>
      <WhyRentlioSliderWrapper>
        {slides.map((slide, i) => (
          <WhyRentlioSlide key={slide.image.id} slide={slide.image} active={state.activeSlide === i} locale={locale} />
        ))}
      </WhyRentlioSliderWrapper>
    </>
  )
}

export default WhyRentlioSlider
