import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import Img from 'gatsby-image'

import {
  CustomerSatisfactionSection,
  CustomerSatisfactionContent,
  Description,
  Title,
  Text,
  StyledLink,
  ScoreLineWrapper,
  Line,
  Dots,
  Range,
  ScoreCategory,
  LineCursor,
  ImageContainer
} from './styles'

import Container from '../../UI/Container'
import Responsive from 'components/Responsive'
import WindowSizeProvider from 'components/WindowSizeProvider'

const CustomerSatisfaction = ({ data }) => {
  const {
    customer: {
      customer_satisfaction: {
        customer_satisfaction_title,
        customer_satisfaction_text,
        customer_satisfaction_link_text,
        customer_satisfaction_url
      }
    }
  } = data

  const { npsImage } = useStaticQuery(graphql`
    query {
      npsImage: file(relativePath: { eq: "images/nps-line.png" }) {
        childImageSharp {
          fluid(maxWidth: 500, quality: 95) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `)

  return (
    <CustomerSatisfactionSection>
      <Container>
        <CustomerSatisfactionContent>
          <Title>{customer_satisfaction_title}</Title>
          <Description>
            <Text dangerouslySetInnerHTML={{ __html: customer_satisfaction_text }} />
            <StyledLink href={customer_satisfaction_url} target="_blank" rel="noreferrer">
              {customer_satisfaction_link_text}
            </StyledLink>
          </Description>
        </CustomerSatisfactionContent>
        <WindowSizeProvider>
          <Responsive
            breakPoint={1024}
            renderDesktop={() => (
              <ScoreLineWrapper>
                <Line>
                  <Range>
                    <span>-100</span>
                    <span>0</span>
                    <span>+100</span>
                  </Range>
                  <Dots>
                    <span />
                    <span />
                    <span />
                  </Dots>
                  <ScoreCategory>
                    <span>GOOD</span>
                    <span>EXCELLENT</span>
                    <span>WORLD-CLASS</span>
                  </ScoreCategory>
                  <LineCursor>
                    <span>RENTLIO</span>
                    <span />
                  </LineCursor>
                </Line>
              </ScoreLineWrapper>
            )}
            renderMobile={() => (
              <ImageContainer>
                <Img fluid={npsImage.childImageSharp.fluid} />
              </ImageContainer>
            )}
          />
        </WindowSizeProvider>
      </Container>
    </CustomerSatisfactionSection>
  )
}

export default CustomerSatisfaction
