import React from 'react'
import Img from 'gatsby-image'
import {
  WhyRentlioContentSection,
  Testimonial,
  TextContent,
  Owner,
  ImageContainer,
  SupportMessage,
  Title,
  Subtitle,
  SupportInfo,
  Divider,
  Column,
  Row
} from './styles'

import Container from '../../UI/Container'
import Responsive from '../../Responsive'
import WindowSizeProvider from '../../WindowSizeProvider'
import TestimonialList from './List'
import TestimonialListMobile from './ListMobile'

const WhyRentlioContent = ({ data }) => {
  const {
    intro: {
      intro_testimonial: { text, name, role, image }
    },
    support: {
      support_message: { title, subtitle },
      support_info: { icon_one, icon_text_one, icon_description_one, icon_two, icon_text_two, icon_description_two }
    },
    testimonials
  } = data

  return (
    <WhyRentlioContentSection>
      <Container>
        <Testimonial>
          <ImageContainer>
            <img src={image.localFile.childImageSharp.fixed.src} alt={name} />
          </ImageContainer>
          <TextContent>
            <div dangerouslySetInnerHTML={{ __html: text }} />
            <Owner>
              {name} <span>{role}</span>
            </Owner>
          </TextContent>
        </Testimonial>
        <SupportMessage>
          <Title>{title}</Title>
          <Subtitle dangerouslySetInnerHTML={{ __html: subtitle }} />
        </SupportMessage>
        <SupportInfo>
          <Column>
            <Row>
              <span dangerouslySetInnerHTML={{ __html: icon_one }} />
              <span>{icon_text_one}</span>
            </Row>
            <span>{icon_description_one}</span>
          </Column>
          <Divider />
          <Column>
            <Row>
              <span dangerouslySetInnerHTML={{ __html: icon_two }} />
              <span>{icon_text_two}</span>
            </Row>
            <span>{icon_description_two}</span>
          </Column>
        </SupportInfo>
        <WindowSizeProvider>
          <Responsive
            breakPoint={1024}
            renderDesktop={() => <TestimonialList data={testimonials} />}
            renderMobile={() => <TestimonialListMobile data={testimonials} />}
          />
        </WindowSizeProvider>
      </Container>
    </WhyRentlioContentSection>
  )
}

export default WhyRentlioContent
