import styled from 'styled-components'

export const WhyRentlioContentSection = styled.section`
  padding: 5rem 0;
  background: linear-gradient(180deg, rgba(247, 247, 247, 0) 0%, #f7f7f7 80%, #ffffff 0%);

  @media screen and (max-width: 425px) {
    padding: 4rem 0;
  }
`
export const Testimonial = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 8rem 0 0 0;

  @media only screen and (max-width: 1024px) {
    flex-direction: column;
    align-items: center;
    margin: 4rem 0;
  }
`
export const ImageContainer = styled.figure`
  width: 480px;
  height: 343px;

  img {
    border-radius: 10px;
  }

  @media only screen and (max-width: 530px) {
    width: 100%;
    height: auto;
  }
`
export const TextContent = styled.div`
  display: flex;
  flex-direction: column;
  flex-basis: 50%;
  justify-content: center;

  p {
    color: ${({ theme }) => theme.colors.veryDarkBlue};
    font-family: 'Barlow';
    font-size: 2.14rem;
    font-weight: 800;
    line-height: 2.85rem;
    width: 80%;
    margin-bottom: 3rem;
  }

  @media only screen and (max-width: 1024px) {
    width: 480px;
    margin-top: 3rem;
  }

  @media only screen and (max-width: 530px) {
    width: 100%;
    margin-bottom: 2rem;

    p {
      width: 100%;
    }
  }

  @media screen and (max-width: 425px) {
    p {
      font-size: 1.7rem;
      line-height: 2.2rem;
      text-align: center;
    }

    h6 {
      display: flex;
      flex-direction: column;
      text-align: center;
    }
  }
`
export const Owner = styled.div`
  color: ${({ theme }) => theme.colors.veryDarkBlue};
  font-family: 'Barlow';
  font-size: 1.28rem;
  font-weight: bold;
  line-height: 1.42rem;

  span {
    color: ${({ theme }) => theme.colors.veryDarkBlue};
    font-family: 'Roboto';
    font-weight: normal;
    font-size: 0.85rem;
    line-height: 1.71rem;
    opacity: 0.6;
  }

  @media screen and (max-width: 425px) {
    display: flex;
    flex-direction: column;
    text-align: center;
  }
`
export const SupportMessage = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 15rem 0 10rem 0;

  @media only screen and (max-width: 768px) {
    margin: 6rem 0;
  }
`
export const Title = styled.h2`
  color: ${({ theme }) => theme.colors.veryDarkBlue};
  font-family: 'Barlow';
  font-size: 7.14rem;
  line-height: initial;
  font-weight: 900;
  background: linear-gradient(
    to right,
    ${({ theme }) => theme.colors.veryDarkBlue},
    ${({ theme }) => theme.colors.darkBlue}
  );
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  margin-bottom: 2rem;
  text-align: center;

  @media only screen and (max-width: 768px) {
    font-size: 3.28rem;
    line-height: 3.9rem;
    text-align: center;
  }
`

export const Subtitle = styled.div`
  color: ${({ theme }) => theme.colors.darkGrey};
  font-size: 1.14rem;
  line-height: 1.71rem;
  text-align: center;
  width: 60%;

  @media only screen and (max-width: 768px) {
    width: 100%;
  }
`

export const Divider = styled.div`
  width: 1px;
  height: 165px;
  background: linear-gradient(180deg, rgba(29, 158, 217, 0) 0%, #1d9ed9 48.96%, rgba(29, 158, 217, 0) 100%);

  @media only screen and (max-width: 768px) {
    transform: rotate(90deg);
    margin: 0 auto;
  }
`

export const SupportInfo = styled.div`
  display: flex;
  margin: 10rem 0;

  @media only screen and (max-width: 768px) {
    flex-direction: column;
    margin: 4rem 0;
  }
`

export const Column = styled.div`
  display: flex;
  flex-direction: column;
  flex-basis: 50%;
  justify-content: center;
  align-items: center;

  & > span {
    color: ${({ theme }) => theme.colors.veryDarkBlue};
    font-size: 1.14rem;
    font-weight: 500;
    line-height: 1.71rem;
    opacity: 0.6;
    margin-top: 1rem;

    @media screen and (max-width: 425px) {
      text-align: center;
    }
  }

  @media only screen and (max-width: 768px) {
    margin: 2rem 0;
  }
`

export const Row = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 1rem;

  span:first-child {
    font-size: 4.28rem;
    margin-top: 1rem;
    margin-right: 1rem;
  }

  span:last-child {
    color: ${({ theme }) => theme.colors.veryDarkBlue};
    font-family: 'Barlow';
    font-size: 7.14rem;
    font-weight: 900;
    line-height: 7.14rem;
  }

  @media only screen and (max-width: 425px) {
    span:first-child {
      font-size: 3.28rem;
      margin-top: 1rem;
      margin-right: 1rem;
    }
    span:last-child {
      font-size: 5.14rem;
      line-height: 5.7rem;
    }
  }
`
