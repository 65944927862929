import styled from 'styled-components'

export const CustomerMessageSection = styled.section`
  padding: 5rem 0;
`

export const CustomerMessageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 10rem;

  @media screen and (max-width: 425px) {
    margin-bottom: 2rem;
  }
`

export const Text = styled.div`
  color: ${({ theme }) => theme.colors.darkGrey};
  font-size: 1.42rem;
  line-height: 2.57rem;
  text-align: center;
  width: 50%;
  margin-bottom: 8rem;

  @media only screen and (max-width: 768px) {
    width: 100%;
    margin-bottom: 4rem;
    font-size: 1.2rem;
    line-height: 2rem;
  }
`
