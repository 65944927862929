import React from 'react'
import { handleTrackedRentlioWebappClick } from 'helper/url'
import { useMixpanel } from 'gatsby-plugin-mixpanel'

import {
  CapteraBadgeSection,
  CapteraBadgeWrapper,
  ImageContainer,
  Description,
  Title,
  Text,
  StyledLink
} from './styles'

import Container from '../../UI/Container'

const CapteraBadge = ({ data }) => {
  const {
    captera: {
      captera_badge: { captera_image, captera_title, captera_text, link_text, url }
    }
  } = data
  const mixpanel = useMixpanel()

  return (
    <CapteraBadgeSection>
      <Container>
        <CapteraBadgeWrapper>
          <ImageContainer>
            <figure>
              <img src={captera_image.localFile.childImageSharp.fluid.src} alt="Captera" />
            </figure>
          </ImageContainer>
          <Description>
            <Title>{captera_title}</Title>
            <Text dangerouslySetInnerHTML={{ __html: captera_text }} />
            <StyledLink href={url} rel="noreferrer" onClick={(e) => handleTrackedRentlioWebappClick(e, url, '', mixpanel, 'capterra_reviews_clicked', 'why_rentlio_page',true, true, true)}>
              {link_text}
            </StyledLink>
          </Description>
        </CapteraBadgeWrapper>
      </Container>
    </CapteraBadgeSection>
  )
}

export default CapteraBadge
