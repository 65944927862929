import React, { useEffect, useState } from 'react'
import Rellax from 'rellax'

import {
  TestimonialListWrapper,
  TestimonialCard,
  TestimonialCardContent,
  TestimonialCardFooter,
  Name,
  Role
} from './styles'

const TestimonialList = ({ data }) => {
  const [position, setPosition] = useState()
  const {
    testimonial_list: {
      text_one,
      name_one,
      role_one,
      user_image_one,
      text_two,
      name_two,
      role_two,
      user_image_two,
      text_three,
      name_three,
      role_three,
      user_image_three
    }
  } = data

  useEffect(() => {
    const rellax = new Rellax('.card', {
      center: true
    })
  }, [])

  return (
    <>
      <TestimonialListWrapper className="cards">
        <TestimonialCard>
          <TestimonialCardContent dangerouslySetInnerHTML={{ __html: text_one }} />
          <TestimonialCardFooter>
            <figure>
              <img src={user_image_one.localFile.childImageSharp.fluid.src} alt="" />
            </figure>
            <div>
              <Name>{name_one}</Name>
              <Role>{role_one}</Role>
            </div>
          </TestimonialCardFooter>
        </TestimonialCard>
        <TestimonialCard
          marginTop="0"
          className="card"
          data-rellax-min-y="-80"
          data-rellax-max-y="80"
          data-rellax-vertical-speed="-4"
          data-rellax-vertical-scroll-axis="y"
        >
          <TestimonialCardContent dangerouslySetInnerHTML={{ __html: text_two }} />
          <TestimonialCardFooter>
            <figure>
              <img src={user_image_two.localFile.childImageSharp.fluid.src} alt="" />
            </figure>
            <div>
              <Name>{name_two}</Name>
              <Role>{role_two}</Role>
            </div>
          </TestimonialCardFooter>
        </TestimonialCard>
        <TestimonialCard>
          <TestimonialCardContent dangerouslySetInnerHTML={{ __html: text_three }} />
          <TestimonialCardFooter>
            <figure>
              <img src={user_image_three.localFile.childImageSharp.fluid.src} alt="" />
            </figure>
            <div>
              <Name>{name_three}</Name>
              <Role>{role_three}</Role>
            </div>
          </TestimonialCardFooter>
        </TestimonialCard>
      </TestimonialListWrapper>
    </>
  )
}

export default TestimonialList
