import React from 'react'

import { WhyRentlioIntroWrapper, WhyRentlioIntroBox, Text, Title, Description, ImageContainer } from './styles'
import Container from '../../UI/Container'

import WhyRentlioSlider from '../Why-rentlio-slider'

const WhyRentlioIntro = ({ data }) => {
  const {
    intro: {
      title,
      description,
      slider: { add_images: slides }
    }
  } = data
  return (
    <WhyRentlioIntroWrapper>
      <Container>
        <WhyRentlioIntroBox>
          <Description>
            <Title>{title}</Title>
            <Text dangerouslySetInnerHTML={{ __html: description }} />
          </Description>
          <ImageContainer>
            <WhyRentlioSlider slides={slides} />
            <span />
          </ImageContainer>
        </WhyRentlioIntroBox>
      </Container>
    </WhyRentlioIntroWrapper>
  )
}

export default WhyRentlioIntro
