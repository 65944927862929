import styled from 'styled-components'

export const CapteraBadgeSection = styled.section`
  padding: 7rem 0;
`

export const CapteraBadgeWrapper = styled.div`
  display: flex;
  margin-bottom: 8rem;

  @media only screen and (max-width: 768px) {
    flex-direction: column;
  }

  @media screen and (max-width: 425px) {
    margin-bottom: 1rem;
  }
`
export const ImageContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-basis: 50%;

  figure {
    max-width: 300px;
    width: 100%;
    height: 100%;
  }

  @media screen and (max-width: 425px) {
    figure {
      max-width: 200px;
    }
  }
`

export const Description = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex-basis: 50%;

  @media only screen and (max-width: 768px) {
    align-items: center;
  }
`

export const Title = styled.h3`
  color: ${({ theme }) => theme.colors.veryDarkBlue};
  font-family: 'Barlow';
  font-size: 2.85rem;
  font-weight: 800;
  line-height: 3.57rem;
  margin-top: 2rem;
  margin-bottom: 2rem;

  @media screen and (max-width: 425px) {
    font-size: 2.5rem;
    line-height: 3rem;
    text-align: center;
  }
`

export const Text = styled.div`
  color: ${({ theme }) => theme.colors.darkGrey};
  font-size: 1.14rem;
  line-height: inherit;
  margin-bottom: 2rem;
`

export const StyledLink = styled.a`
  color: ${({ theme }) => theme.colors.vividBlue};
  font-size: 1.42rem;
  font-weight: 500;
  text-decoration: none;
  text-transform: uppercase;
  margin-top: 2rem;
  position: relative;
  align-self: flex-start;

  &:hover {
    ::after {
      content: '';
      position: absolute;
      width: 100%;
      height: 6px;
      background-color: ${({ theme }) => theme.colors.vividBlue};
      opacity: 0.3;
      bottom: 6px;
      left: 0;
    }
  }

  @media only screen and (max-width: 768px) {
    align-self: center;
    font-size: 1.1rem;
  }
`
