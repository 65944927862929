import React from 'react'
import { graphql } from 'gatsby'
import styled from 'styled-components'

import SEO from 'components/SEO'
import Layout from 'components/Layout'
import Header from 'components/Header'
import Footer from 'components/Footer'
import WhyRentlioIntro from 'components/Why-rentlio-screen/Why-rentlio-intro'
import WhyRentlioContent from 'components/Why-rentlio-screen/Why-rentlio-content'
import BlogCarousel from 'components/Blog-carousel'
import CtaTrial from 'components/CTA/Trial'
import CapteraBadge from 'components/Why-rentlio-screen/Captera-badge'
import CustomerSatisfaction from 'components/Why-rentlio-screen/Customer-satisfaction'
import CustomerMessage from 'components/Why-rentlio-screen/Customer-message'

const MainContent = styled.main`
  background-color: #ffffff;
`

const WhyRentlio = ({ data, pageContext, location }) => {
  const {
    why_rentlio: {
      title,
      excerpt,
      yoast_meta: { yoast_wpseo_title, yoast_wpseo_metadesc }
    },
    carouselPosts: { edges: carouselPosts }
  } = data

  const seo = {
    title: yoast_wpseo_title || title,
    description: yoast_wpseo_metadesc || excerpt,
    lang: pageContext.locale,
    pathname: location.pathname
  }

  return (
    <Layout locale={pageContext.locale}>
      <SEO
        title={seo.title}
        description={seo.description}
        pathname={seo.pathname}
        lang={seo.lang}
        location={location}
      />
      <Header mode="light" locale={pageContext.locale} color={'#BC242E'} location={location} />
      <WhyRentlioIntro data={data.why_rentlio} />
      <MainContent>
        <WhyRentlioContent data={data.why_rentlio} />
        <CapteraBadge data={data.why_rentlio} />
        <CustomerSatisfaction data={data.why_rentlio} />
        <BlogCarousel posts={carouselPosts} locale={pageContext.locale} location={location} />
        <CustomerMessage data={data.why_rentlio.customer.customer_success_message} location={location} />
        <CtaTrial data={data.why_rentlio.ctaData.cta} location={location} />
      </MainContent>
      <Footer locale={pageContext.locale} location={location} />
    </Layout>
  )
}

export default WhyRentlio

export const query = graphql`
  fragment TestimonialImg on wordpress__wp_media {
    localFile {
      childImageSharp {
        fluid(quality: 80, maxWidth: 45) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }

  query($id: Int, $locale: String!) {
    why_rentlio: wordpressPage(wordpress_id: { eq: $id }, lang: { eq: $locale }) {
      title
      excerpt
      yoast_meta {
        yoast_wpseo_title
        yoast_wpseo_metadesc
      }
      intro: acf {
        title
        slider {
          add_images {
            image {
              id
              localFile {
                childImageSharp {
                  fluid(quality: 90, maxWidth: 500) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
            }
          }
        }
        description
        intro_testimonial {
          text
          name
          role
          image {
            localFile {
              childImageSharp {
                fixed(quality: 75, width: 480, height: 343) {
                  ...GatsbyImageSharpFixed_withWebp
                }
              }
            }
          }
        }
      }
      ctaData: acf {
        cta {
          title
          description
          button_text
          button_link
          image_text
          image {
            localFile {
              childImageSharp {
                fluid(quality: 85, maxWidth: 1200) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
        }
      }
      support: acf {
        support_message {
          title
          subtitle
        }
        support_info {
          icon_one
          icon_text_one
          icon_description_one
          icon_two
          icon_text_two
          icon_description_two
        }
      }

      testimonials: acf {
        testimonial_list {
          text_one
          name_one
          role_one
          user_image_one {
            ...TestimonialImg
          }
          text_two
          name_two
          role_two
          user_image_two {
            ...TestimonialImg
          }
          text_three
          name_three
          role_three
          user_image_three {
            ...TestimonialImg
          }
        }
      }

      captera: acf {
        captera_badge {
          captera_image {
            localFile {
              childImageSharp {
                fluid(quality: 80, maxWidth: 300) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
          captera_title
          captera_text
          link_text
          url
        }
      }

      customer: acf {
        customer_success_message {
          content
          button_text
          button_link
        }
        customer_satisfaction {
          customer_satisfaction_title
          customer_satisfaction_text
          customer_satisfaction_link_text
          customer_satisfaction_url
        }
      }
    }

    carouselPosts: allWordpressPost(filter: { tags: { elemMatch: { slug: { eq: "hotel" } } }, lang: { eq: $locale } }) {
      edges {
        node {
          id
          title
          slug
          acf {
            name_of_property
          }
          featured_media {
            localFile {
              childImageSharp {
                fluid(quality: 80, maxWidth: 320) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
        }
      }
    }
  }
`
