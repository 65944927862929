import React from 'react'

import { WhyRentlioSlideWrapper, StyledImg } from './styles'

const WhyRentlioSlide = ({ slide, active }) => {
  const {
    localFile: {
      childImageSharp: { fluid }
    }
  } = slide
  return (
    <WhyRentlioSlideWrapper className={active ? 'active' : ''}>
      <StyledImg placeholderStyle={{ visibility: 'hidden' }} fluid={fluid} />
    </WhyRentlioSlideWrapper>
  )
}

export default WhyRentlioSlide
