import styled from 'styled-components'

export const CustomerSatisfactionSection = styled.section`
  background-color: ${({ theme }) => theme.colors.veryDarkBlue};
  padding: 8rem 0;
  position: relative;

  &::after {
    content: '';
    position: absolute;
    background: white;
    width: 100%;
    height: 100px;
    bottom: -2px;

    @supports (clip-path: polygon(0 0)) or (-webkit-clip-path: polygon(0 0)) {
      -webkit-clip-path: polygon(0 70%, 100% 0, 100% 100%, 0 100%);
      -webkit-backface-visibility: hidden;
      -moz-backface-visibility: hidden;
      backface-visibility: hidden;
      clip-path: polygon(0 50%, 100% 0, 100% 100%, 0 100%);
    }
  }

  @media screen and (max-width: 425px) {
    padding: 4rem 0;
  }
`
export const CustomerSatisfactionContent = styled.div`
  display: flex;
  margin-bottom: 5rem;

  @media only screen and (max-width: 1024px) {
    flex-direction: column;
    align-items: center;
  }
`

export const Title = styled.h2`
  color: ${({ theme }) => theme.colors.lightestGrey};
  font-family: 'Barlow';
  font-size: 7.14rem;
  font-weight: 900;
  line-height: 7.14rem;

  @media only screen and (max-width: 1024px) {
    font-size: 2rem;
    line-height: 2.5rem;
    text-align: center;
    margin-bottom: 2rem;
  }
`

export const Description = styled.div`
  display: flex;
  flex-direction: column;
  padding: 1.4rem 0;
  width: 90%;
`

export const Text = styled.div`
  color: ${({ theme }) => theme.colors.lightestGrey};
  font-size: 1.14rem;
  line-height: inherit;
  margin-bottom: 2rem;

  @media screen and (max-width: 768px) {
    line-height: 1.8rem;
  }
`

export const StyledLink = styled.a`
  color: ${({ theme }) => theme.colors.lightestGrey};
  font-weight: 500;
  text-decoration: none;
  text-transform: uppercase;
  margin-top: 2rem;
  position: relative;
  align-self: flex-start;

  &:hover {
    ::after {
      content: '';
      position: absolute;
      width: 100%;
      height: 6px;
      background-color: ${({ theme }) => theme.colors.lightestGrey};
      opacity: 0.3;
      bottom: 3px;
      left: 0;
      opacity: 0.2;
    }
  }

  @media only screen and (max-width: 1024px) {
    align-self: center;
  }
`
export const ScoreLineWrapper = styled.div`
  margin: 15rem 0;
  @media only screen and (max-width: 1024px) {
    display: none;
  }
`
export const Line = styled.div`
  width: 100%;
  height: 8px;
  background: linear-gradient(90deg, #1d9ed9 0%, #a9cf3a 100%);
  box-shadow: 0px 4px 10px rgba(0, 133, 255, 0.2);
  border-radius: 4px;
  position: relative;
`
export const Range = styled.div`
  span {
    position: absolute;
    color: ${({ theme }) => theme.colors.lightestGrey};
    opacity: 0.6;
    top: -50px;

    &:nth-child(1) {
      left: 0%;
    }

    &:nth-child(2) {
      left: 50.5%;
    }

    &:nth-child(3) {
      left: 97%;
    }
  }
`

export const ScoreCategory = styled.div`
  span {
    position: absolute;
    color: ${({ theme }) => theme.colors.lightestGrey};
    opacity: 0.6;
    top: 30px;

    &:nth-child(1) {
      left: 56.8%;
    }

    &:nth-child(2) {
      left: 73%;
    }

    &:nth-child(3) {
      left: 90%;
    }
  }
`

export const Dots = styled.div`
  span {
    position: absolute;
    width: 20px;
    height: 20px;
    background: ${({ theme }) => theme.colors.lightestGrey};
    box-shadow: 0px 4px 10px rgba(0, 133, 255, 0.2);
    border-radius: 50%;
    transform: translateY(-30%);

    &:nth-child(1) {
      left: 50%;
    }

    &:nth-child(2) {
      left: 65%;
    }

    &:nth-child(3) {
      left: 85%;
    }
  }
`

export const LineCursor = styled.div`
  span {
    position: absolute;
    color: ${({ theme }) => theme.colors.lightestGrey};

    &:nth-child(1) {
      left: 86.8%;
      top: -100px;
    }

    &:nth-child(2) {
      left: 73%;
      width: 2px;
      height: 120px;
      left: 89%;
      top: -64px;
      width: 2px;
      background: linear-gradient(180deg, rgba(234, 234, 234, 0) 0%, #eaeaea 63.25%);
    }
  }
`
export const ImageContainer = styled.figure`
  width: 100%;
  max-width: 500px;
  margin: 0 auto 5rem auto;
`
